/* Custom Zulip icons generated with webfont-loader */

@font-face {
    font-family: 'zulip-icons';
    src: url("files/zulip-icons.9e1a38b1bbdd31acefac.eot?#iefix") format("embedded-opentype"),
url("files/zulip-icons.9e1a38b1bbdd31acefac.woff") format("woff"),
url("files/zulip-icons.9e1a38b1bbdd31acefac.woff2") format("woff2"),
url("files/zulip-icons.9e1a38b1bbdd31acefac.ttf") format("truetype"),
url("files/zulip-icons.9e1a38b1bbdd31acefac.svg#zulip-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i.zulip-icon {
    font-family: 'zulip-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    font-size: inherit;
    line-height: 100%;

    text-decoration: inherit;
    display: inline-block;
    speak: none;
}

.zulip-icon::before {
    font-family: 'zulip-icons' !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.zulip-icon.zulip-icon-bot::before {
    content: "\f101";
}
.zulip-icon.zulip-icon-ellipsis-v-solid::before {
    content: "\f102";
}
.zulip-icon.zulip-icon-gif::before {
    content: "\f103";
}
.zulip-icon.zulip-icon-globe::before {
    content: "\f104";
}
